import { TDefaultError, TDefaultOptions, TCatalog } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'
import { useNotification } from '@/core/composables'

export const useDeleteRecord = ({
  handlers,
}: TDefaultOptions<void, TCatalog>) => {
  const { deleteRecord: deleteRecordFn } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const deleteRecordMutation = useMutation<
    void,
    AxiosError<TDefaultError>,
    TCatalog
  >({
    mutationFn: (payload) =>
      deleteRecordFn({
        ids: {
          parentId: payload.uuid,
        },
      }),
    onSuccess: handlers?.onSuccess,
    onError: (error) => errorHttpNotification({ error }),
  })

  const onDelete = async (payload: TCatalog) => {
    await deleteRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    onDelete,
    deleteRecordMutation,
  })
}
