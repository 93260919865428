import { reactive, computed, onMounted } from 'vue'
import { createInjectionState } from '@vueuse/shared'
import { useLocalStorage } from '@vueuse/core'

type ThemeConfig = {
  mode: 'dark' | 'light'
}

const [useProvideTheme, injectModule] = createInjectionState(() => {
  const themeModeStorage = useLocalStorage<ThemeConfig['mode']>('mode', 'light')

  const themeConfig = reactive<ThemeConfig>({
    mode: 'light',
  })

  const changeThemeSettings = (mode: ThemeConfig['mode']) => {
    themeConfig.mode = mode
    themeModeStorage.value = mode
    onChangeTheme(mode)
  }

  const onChangeTheme = (mode: ThemeConfig['mode']) => {
    const root = document.getElementsByTagName('html')

    if (root.length) {
      const html = root[0]

      html.classList.remove(mode === 'light' ? 'dark' : 'light')
      html.classList.add(mode)
    }
  }

  const isDarkTheme = computed(() => themeConfig.mode === 'dark')
  const isLightTheme = computed(() => themeConfig.mode === 'light')

  onMounted(() => {
    if (themeModeStorage.value == 'dark') {
      changeThemeSettings(themeModeStorage.value)
    }
  })

  return {
    themeConfig,
    isDarkTheme,
    isLightTheme,
    changeThemeSettings,
  }
})

export { useProvideTheme }

export const useTheme = () => {
  const module = injectModule()

  if (module == null)
    throw new Error(
      'Please call `useProvideTheme` on the appropriate parent component',
    )

  return module
}
