import { type TPostOptions, useEndpoints } from './useEndpoints'

export const useAddRecord = () => {
  const { postFn } = useEndpoints()

  const addRecordFn = async <TAddRecord = any, TReturnType = any>(
    options: TPostOptions<TAddRecord>,
  ): Promise<TReturnType> => {
    return await postFn<TAddRecord, TReturnType>(options)
  }

  return { addRecordFn }
}
