import api from '@/core/config/axios'
import { useDownload } from '../useDownload'
import { TFilters } from '@/core/types'
import { toValue } from 'vue'

export type TExportRecordsFnOptions = {
  model?: string
  version?: string
  config?: TFilters
  extension?: string
}

export const useExportRecords = () => {
  const { downloadFn } = useDownload()

  const exportRecordsFn = async (options: TExportRecordsFnOptions) => {
    const { config, extension } = options

    const urlSearchParams = [
      config?.page ? `page=${toValue(config.page) ?? 1}` : null,
      toValue(config?.filters ?? ''),
    ]
      .filter(Boolean)
      .join('&')

    try {
      const response = await api.get(
        `/${options.version ?? 'v1'}/${options?.model}-export${urlSearchParams ? '?' + urlSearchParams : ''}`,
        {
          responseType: 'blob',
          params: {
            format: extension ?? 'csv',
          },
        },
      )

      downloadFn({
        response,
        extension,
        formatName: options.model,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return { exportRecordsFn }
}
