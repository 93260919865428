export const authRoles = {
  ADMIN: 'admin',
  EMPLOYER: 'employer',
}

export const roles = [
  {
    label: authRoles.ADMIN,
  },
]
