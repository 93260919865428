import { TDefaultError, TDefaultOptions } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'
import { TCity } from '../models'

export const useAddRecord = ({ handlers }: TDefaultOptions<TCity, TCity>) => {
  const { addRecord: addRecordFn } = useModuleService()

  const addRecordMutation = useMutation<
    TCity,
    AxiosError<TDefaultError>,
    TCity
  >({
    mutationFn: (payload: TCity) =>
      addRecordFn({
        payload,
      }),
    onSuccess: handlers?.onSuccess,
  })

  const onAdd = async (payload: TCity) => {
    await addRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    onAdd,
    addRecordMutation,
  })
}
