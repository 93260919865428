import { TDefaultError, TDefaultOptions } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../service'

export const useLogout = ({ handlers }: TDefaultOptions<void, any>) => {
  const { logout } = useModuleService()

  const onLogoutMutation = useMutation<void, AxiosError<TDefaultError>>({
    mutationFn: logout,
    onSuccess: handlers?.onSuccess,
  })

  const onLogout = async () => {
    await onLogoutMutation.mutateAsync()
  }

  return toReactive({
    onLogoutMutation,
    onLogout,
  })
}
