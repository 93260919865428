import { TDefaultError, TUser } from '@/core/types'
import { useQuery } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../service'

export const useGetAuthUser = () => {
  const { getUser } = useModuleService()

  const onGetUserQuery = useQuery<TUser, AxiosError<TDefaultError>>({
    queryKey: ['auth-user'],
    queryFn: getUser,
    enabled: false,
  })

  const onGetUser = async () => await onGetUserQuery.refetch()

  return toReactive({
    onGetUserQuery,
    onGetUser,
  })
}
