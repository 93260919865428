import { useDebounceFn, useLocalStorage } from '@vueuse/core'
import { format, toZonedTime } from 'date-fns-tz'
import { ref, watch } from 'vue'
import { TDate } from './useDatePicker'

export type ToQueryStringFilters = {
  param?: string
  order_by?: string
  dates?: Record<string, TDate>
  relations?: Record<string, Record<string, string>>
  between?: Record<string, [number, number]>
  [key: string]: any
}

type ToQueryStringDateOptions = {
  dateFormat?: string
  enableFormat?: boolean
}

type ToQueryStringOptions = {
  searchColumns?: string[]
  persistFilters?: boolean
  persistKey?: string
  dateSettings?: ToQueryStringDateOptions
}

export const useToQueryString = (
  filters: ToQueryStringFilters,
  options: ToQueryStringOptions = {},
) => {
  const isMounted = ref(false)
  const delay = ref(250)
  const rawParams = ref<string[]>([])
  const stringParams = ref<string>()
  const { searchColumns = [], dateSettings } = options

  const debounceParseQueryString = useDebounceFn(() => {
    parseQueryString()
  }, delay.value)

  const encode = (key: string, value: any): void => {
    if (Array.isArray(value)) {
      value.forEach((subValue) =>
        rawParams.value.push(
          `${key}[]=${encodeURIComponent(subValue.toString())}`,
        ),
      )
    } else {
      rawParams.value.push(`${key}=${encodeURIComponent(value)}`)
    }
  }

  const processRelations = (
    relations: Record<string, Record<string, string>>,
  ): void => {
    Object.entries(relations).forEach(([key, value]) => {
      Object.entries(value).forEach(([childKey, childValue]) => {
        if (childValue !== '') {
          rawParams.value.push(
            `where_relation=${encodeURIComponent(
              childValue,
            )},${encodeURIComponent(`${key}.${childKey}`)}`,
          )
        }
      })
    })
  }

  const processBetweenDates = (dates: ToQueryStringFilters['dates']): void => {
    Object.entries(dates ?? {}).forEach(([key, value]) => {
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        !!value[0] &&
        !!value[1]
      ) {
        const formattedStartDate = dateSettings?.enableFormat
          ? formatDate(value[0].toString(), dateSettings?.dateFormat)
          : value[0].toString()
        const formattedEndDate = dateSettings?.enableFormat
          ? formatDate(value[1].toString(), dateSettings?.dateFormat)
          : value[1].toString()
        rawParams.value.push(
          `between_dates=${formattedStartDate},${formattedEndDate},${key}`,
        )
      }
    })
  }

  const processBetween = (between: Record<string, [number, number]>): void => {
    Object.entries(between).forEach(([key, value]) => {
      if (value.length === 2) {
        rawParams.value.push(
          `${key}>${encodeURIComponent(value[0].toString())}`,
        )
        rawParams.value.push(
          `${key}<${encodeURIComponent(value[1].toString())}`,
        )
      }
    })
  }

  const processFilters = (filters: Record<string, any>): void => {
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'param' && searchColumns.length > 0 && filters[key]) {
        const combinedValue = `${value},${searchColumns.join(',')}`
        encode(key, combinedValue)
      } else if (
        value !== '' &&
        !Array.isArray(value) &&
        typeof value === 'object' &&
        key !== 'dates' &&
        key !== 'between' &&
        key !== 'relations'
      ) {
        processFilters(value)
      } else if (
        value !== '' &&
        key !== 'dates' &&
        key !== 'between' &&
        key !== 'relations'
      ) {
        encode(key, value)
      }
    })
  }

  const formatDate = (
    dateStr: string,
    formatStr: string = 'yyyy-MM-dd',
  ): string => {
    const date = new Date(dateStr)
    const zonedDate = toZonedTime(date, 'UTC')
    return format(zonedDate, formatStr)
  }

  const processPersistFilters = () => {
    const lsFilters = useLocalStorage<ToQueryStringFilters>(
      `${options.persistKey}`,
      {},
    )
    lsFilters.value = filters
  }

  const parseQueryString = () => {
    rawParams.value = []
    isMounted.value = true

    if (filters.relations) processRelations(filters.relations)
    if (filters.dates) processBetweenDates(filters.dates)
    if (filters.between) processBetween(filters.between)

    processFilters(filters)

    stringParams.value = rawParams.value.join('&')

    if (options.persistFilters && options.persistKey) processPersistFilters()
  }

  watch(
    filters,
    () => {
      !isMounted.value ? parseQueryString() : debounceParseQueryString()
    },
    { immediate: true },
  )

  return {
    stringParams,
    rawParams,
  }
}
