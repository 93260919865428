import { type TEndpoint } from '@/core/types'
import { useEndpoints } from './useEndpoints'

export type TLoadRecordOptions = {
  endpoint?: string
}

export const useLoadRecord = () => {
  const { getFn } = useEndpoints()

  const loadRecordFn = async <TLoadRecord = any>(
    options: TEndpoint,
  ): Promise<TLoadRecord> => {
    return await getFn<TLoadRecord>(options)
  }

  return { loadRecordFn }
}
