import { useModuleService } from '../services'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { TDefaultError, TDefaultOptions, TUser, TUserForm } from '@/core/types'
import { AxiosError } from 'axios'

export const useUpdateRecord = ({
  handlers,
}: TDefaultOptions<TUser, TUser & TUserForm>) => {
  const { updateRecord: updateRecordFn } = useModuleService()

  const updateRecordMutation = useMutation<
    TUser,
    AxiosError<TDefaultError>,
    TUser & TUserForm
  >({
    mutationFn: (payload: TUser) =>
      updateRecordFn({
        payload,
        ids: {
          parentId: payload.uuid,
        },
      }),
    onSuccess: handlers?.onSuccess,
  })

  const onEdit = async (payload: TUser & TUserForm) => {
    await updateRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    onEdit,
    updateRecordMutation,
  })
}
