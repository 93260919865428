<script setup lang="ts">
import { useProvideTheme } from './core/config/theme/useTheme'
import { useProvideLang } from './core/config/i18n/useLang'
import { useNetwork } from '@vueuse/core'
import { DotNoConnectionOverlay } from '@/core/components'
import { LoadingOverlay } from './core/components'
import { useAuthStore } from './modules/auth'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

useProvideTheme()
useProvideLang()
const { isOnline } = useNetwork()
const auth = useAuthStore()
</script>

<template>
  <Toast />
  <ConfirmDialog />
  <LoadingOverlay />
  <DotNoConnectionOverlay v-if="!isOnline" />
  <router-view v-if="!auth.loaders.isAuthenticating" />
  <VueQueryDevtools />
</template>

<style lang="scss">
html {
  @apply scrollbar-hide;
  body {
    @apply w-full overflow-x-hidden;
    @apply dark:bg-surface-800 dark:text-surface-0;
  }
}

label {
  @apply font-semibold text-sm;
}

input {
  @apply w-full;
}

.icon {
  &--sm {
    @apply text-base;
  }
  &--md {
    @apply text-lg;
  }
  &--lg {
    @apply text-xl;
  }
  &--xl {
    @apply text-2xl;
  }
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-all duration-200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}

html.dark .p-dialog {
  background-color: rgb(
    var(--surface-900)
  ) !important; /* Color oscuro cuando .dark está en html */
}
</style>
