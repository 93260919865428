import api from '@/core/config/axios'
import type { TFilters, TEndpoint } from '@/core/types'
import { AxiosRequestConfig } from 'axios'

export type TPostOptions<T> = {
  payload: T | FormData
  config?: AxiosRequestConfig<T>
  endpoint?: string
}

export type TDeleteOptions = {
  ids?: Array<string | number>
  endpoint?: string
}

export type TPutDefaultPayload = {
  id?: string | number
}

export type TPutOptions<T> = {
  payload: (T & TPutDefaultPayload) | FormData
  config?: AxiosRequestConfig<T & TPutDefaultPayload>
  endpoint?: string
}

export const useEndpoints = () => {
  const getFn = async <T = any>(options: TEndpoint): Promise<T> => {
    return await api
      .get(`${options.endpoint}`)
      .then((response) => response.data)
  }

  const getAllFn = async <T = any>(options: TFilters): Promise<T> => {
    const urlSearchParams = [
      options.page ? `page=${options.page ?? 1}` : null,
      options.filters ?? '',
    ]
      .filter(Boolean)
      .join('&')

    return await api
      .get(`${options.endpoint}${urlSearchParams ? '?' + urlSearchParams : ''}`)
      .then((response) => response.data)
  }

  const postFn = async <T = any, Q = any>(
    options: TPostOptions<T>,
  ): Promise<Q> => {
    return await api
      .post(`${options.endpoint}`, options.payload, {
        ...options.config,
      })
      .then((response) => response.data)
  }

  const putFn = async <T = any, Q = any>(
    options: TPutOptions<T>,
  ): Promise<Q> => {
    return await api
      .put(
        `${options.endpoint}`,
        {
          ...options.payload,
        },
        {
          ...options.config,
        },
      )
      .then((response) => response.data)
  }

  const deleteFn = async <T = void>(
    options: Omit<TDeleteOptions, 'ids'>,
  ): Promise<T> => {
    return await api
      .delete(`${options.endpoint}`)
      .then((response) => response.data)
  }

  const deleteManyFn = async <T>(options: TDeleteOptions): Promise<T> => {
    return await api
      .delete(`${options.endpoint}/delete`, {
        params: {
          ids: options.ids,
        },
      })
      .then((response) => response.data)
  }

  return {
    getFn,
    getAllFn,
    postFn,
    deleteFn,
    deleteManyFn,
    putFn,
  }
}
