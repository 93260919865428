import { TDefaultError, TDefaultOptions, TCatalog } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'

export const useAddRecord = ({
  handlers,
}: TDefaultOptions<TCatalog, TCatalog>) => {
  const { addRecord: addRecordFn } = useModuleService()

  const addRecordMutation = useMutation<
    TCatalog,
    AxiosError<TDefaultError>,
    TCatalog
  >({
    mutationFn: (payload: TCatalog) =>
      addRecordFn({
        payload,
      }),
    onSuccess: handlers?.onSuccess,
  })

  const onAdd = async (payload: TCatalog) => {
    await addRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    onAdd,
    addRecordMutation,
  })
}
