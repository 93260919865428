import { createInjectionState } from '@vueuse/core'
import { computed, reactive, ref } from 'vue'
import {
  TCandidate,
  useExportRecords,
  useLoadRecord,
  useLoadRecords,
} from '../..'
import { ToQueryStringFilters, useToQueryString } from '@/core/composables'

const [useProvideModuleState, injectModule] = createInjectionState(() => {
  const currentPage = ref(1)
  const form = ref<TCandidate>({} as TCandidate)

  const filters = reactive<ToQueryStringFilters>({
    search: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const query = useToQueryString(filters, {
    dateSettings: {
      enableFormat: true,
    },
  })

  const { loadRecordsQuery } = useLoadRecords({
    currentPage,
    filters: query.stringParams,
    key: 'candidates',
  })

  const { loadRecordMutation, onGetCandidate } = useLoadRecord({
    handlers: {
      onSuccess: (payload) => {
        form.value = payload as TCandidate
      },
    },
  })

  const { exportRecordsMutation, onExportRecords } = useExportRecords()

  const onExport = async () => {
    await onExportRecords({ filters: query.stringParams.value })
  }

  const data = computed(() => loadRecordsQuery.data?.data)
  const pagination = computed(() => loadRecordsQuery.data?.meta)

  const loaders = computed(() => ({
    isLoadingRecords: loadRecordsQuery.isLoading,
    isLoadingRecord: loadRecordMutation.isPending,
    isExportingRecords: exportRecordsMutation.isPending,
  }))

  const errorsBag = computed(() => ({}))

  return {
    errorsBag,
    currentPage,
    data,
    filters,
    form,
    loaders,
    pagination,
    onGetCandidate,
    onExport,
  }
})

export { useProvideModuleState }

export const useModuleState = () => {
  const module = injectModule()

  if (module == null)
    throw new Error(
      'Please call `useProvideModuleState` on the appropriate parent component',
    )

  return module
}
