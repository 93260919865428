import {
  TExportRecordsFnOptions,
  TServiceEndpointsSettings,
  useExportRecords,
  useServiceEndpoints,
} from '@/core/composables'
import { TUsers } from '../models'
import { TUser } from '@/core/types'

export const serviceSettings: TServiceEndpointsSettings = {
  version: '1',
  versionPrefix: 'v',
  config: {
    default: {
      model: 'users',
    },
  },
}

export const useModuleService = () => {
  const { config } = serviceSettings

  const endpoints = useServiceEndpoints<TUsers, TUser>({
    ...serviceSettings,
  })

  const { exportRecordsFn } = useExportRecords()

  return {
    ...endpoints,
    exportRecords: async (options: TExportRecordsFnOptions) =>
      await exportRecordsFn({
        ...options,
        model: config?.default?.model ?? '',
      }),
  }
}
