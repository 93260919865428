import { useNotification, useQueryEvents } from '@/core/composables'
import { toReactive } from '@vueuse/core'
import { TStates } from '../models'
import { AxiosError } from 'axios'
import { TDefaultError, TLoadOptions } from '@/core/types'
import { useQuery } from '@tanstack/vue-query'
import { useModuleService } from '../services'

export const useLoadRecords = ({
  currentPage = 1,
  enabled = true,
  filters = '',
  key,
  handlers,
}: TLoadOptions<TStates>) => {
  const { loadRecords: loadRecordsFn } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const loadRecordsQuery = useQuery<TStates, AxiosError<TDefaultError>>({
    queryKey: [key, currentPage, filters],
    queryFn: ({ queryKey }) =>
      loadRecordsFn({
        filters: queryKey[2] as string,
        page: queryKey[1] as number,
      }),
    refetchOnWindowFocus: false,
    enabled: !!enabled,
  })

  useQueryEvents(loadRecordsQuery, {
    onError: (error) => errorHttpNotification({ error }),
    onSuccess: (data) => handlers?.onSuccess?.(data),
  })

  return toReactive({
    loadRecordsQuery,
  })
}
