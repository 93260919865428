import { toReactive } from '@vueuse/core'
import { TDefaultError, TDefaultOptions } from '@/core/types'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/vue-query'
import { useModuleService } from '../service'
import { TRecoverForm } from '../models'

export const useRequestRecover = ({
  handlers,
}: TDefaultOptions<void, TRecoverForm>) => {
  const { requestPasswordChange } = useModuleService()
  const onRequestRecoverMutation = useMutation<
    void,
    AxiosError<TDefaultError>,
    TRecoverForm
  >({
    mutationFn: requestPasswordChange,
    onSuccess: handlers?.onSuccess,
  })
  const onRequestRecover = async (email: TRecoverForm) => {
    await onRequestRecoverMutation.mutateAsync(email)
  }

  return toReactive({
    onRequestRecover,
    onRequestRecoverMutation,
  })
}
