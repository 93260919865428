import { createInjectionState } from '@vueuse/core'
import { computed, reactive, ref, toValue } from 'vue'
import {
  TCity,
  useAddRecord,
  useDeleteRecord,
  useLoadRecords,
  useLoadStates,
  useUpdateRecord,
} from '../..'
import {
  ToQueryStringFilters,
  useServiceHandlers,
  useToQueryString,
} from '@/core/composables'

const [useProvideModuleState, injectModule] = createInjectionState(() => {
  const currentPage = ref(1)
  const form = ref<TCity>({} as TCity)

  const filters = reactive<ToQueryStringFilters>({
    search: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const query = useToQueryString(filters, {
    dateSettings: {
      enableFormat: true,
    },
  })

  const serviceHandlers = useServiceHandlers<TCity, 'query'>({
    model: 'cities',
    currentPage: computed(() => toValue(currentPage)),
    filterParams: computed(() => toValue(query.stringParams) ?? ''),
    queryType: 'query',
    primaryKey: 'uuid',
  })

  const { onDelete } = useDeleteRecord({
    handlers: {
      onSuccess: (_, payload) => {
        payload && serviceHandlers.deleteRecord(payload)
      },
    },
  })

  const { onAdd, addRecordMutation } = useAddRecord({
    handlers: {
      onSuccess: (payload) => payload && serviceHandlers.addRecord(payload),
    },
  })

  const { loadRecordsQuery } = useLoadRecords({
    currentPage,
    filters: query.stringParams,
    key: 'cities',
  })

  const { onEdit, updateRecordMutation } = useUpdateRecord({
    handlers: {
      onSuccess: (payload) => payload && serviceHandlers.updateRecord(payload),
    },
  })

  const { loadStatesQuery } = useLoadStates({})

  const data = computed(() => loadRecordsQuery.data?.data)
  const states = computed(() => loadStatesQuery.data)
  const pagination = computed(() => loadRecordsQuery.data?.meta)

  const loaders = computed(() => ({
    isLoadingRecords: loadRecordsQuery.isLoading,
    isAddingRecord: addRecordMutation.isPending,
    isUpdatingRecord: updateRecordMutation.isPending,
    isStatesLoading: loadStatesQuery.isLoading,
  }))

  const errorsBag = computed(() => ({
    onAdd: addRecordMutation.error?.response?.data,
    onEdit: updateRecordMutation.error?.response?.data,
  }))

  return {
    states,
    errorsBag,
    currentPage,
    data,
    filters,
    form,
    loaders,
    pagination,
    onDelete,
    onEdit,
    onAdd,
  }
})

export { useProvideModuleState }

export const useModuleState = () => {
  const module = injectModule()

  if (module == null)
    throw new Error(
      'Please call `useProvideModuleState` on the appropriate parent component',
    )

  return module
}
