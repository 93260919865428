import { authRoles } from '@/core/constants'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN } = authRoles

const Skills = () =>
  import(/* webpackChunkName: "login-view" */ './presentation/pages/index.vue')

export const moduleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/skills',
    name: 'skills',
    component: Skills,
    meta: {
      title: 'Skills',
      auth: {
        roles: [ADMIN], // Roles permitidos
        redirect: '/auth/login', // Redirección si no está autenticado
        forbiddenRedirect: '/error', // Redirección si no tiene los roles necesarios
      },
    },
  },
]
