import { useNotification } from '@/core/composables'
import { TDefaultError, TExportOptions } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'

export const useExportRecords = () => {
  const { exportRecords: exportRecordsFn } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const exportRecordsMutation = useMutation<
    any,
    AxiosError<TDefaultError>,
    TExportOptions
  >({
    mutationFn: (payload) =>
      exportRecordsFn({
        config: {
          filters: payload.filters,
        },
      }),
    onError: (error) => errorHttpNotification({ error }),
  })

  const onExportRecords = async (payload: TExportOptions) => {
    await exportRecordsMutation.mutateAsync(payload)
  }

  return toReactive({
    exportRecordsMutation,
    onExportRecords,
  })
}
