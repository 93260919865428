import { TRecordData, useNotification } from '@/core/composables'
import {
  TDefaultError,
  TDefaultOptions,
  TRecordAction,
  TUser,
} from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'

export const useActionRecord = ({
  handlers,
}: TDefaultOptions<TUser, TRecordData>) => {
  const { actionRecord } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const actionRecordMutation = useMutation<
    TUser,
    AxiosError<TDefaultError>,
    TRecordData
  >({
    mutationFn: (payload) =>
      actionRecord({
        ...payload,
        ids: {
          parentId: payload.id,
        },
      }),
    onSuccess: handlers?.onSuccess,
    onError: (error) => errorHttpNotification({ error }),
  })

  const onAction = async (action: TRecordAction) => {
    await actionRecordMutation.mutateAsync(action)
  }

  return toReactive({ actionRecordMutation, onAction })
}
