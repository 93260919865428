import {
  TServiceEndpointsSettings,
  useServiceEndpoints,
} from '@/core/composables'
import { TStates } from '../models'
import { TCatalog } from '@/core/types'

export const serviceSettings: TServiceEndpointsSettings = {
  version: '1',
  versionPrefix: 'v',
  config: {
    default: {
      model: 'states',
    },
  },
}

export const useModuleService = () => {
  const endpoints = useServiceEndpoints<TStates, TCatalog>({
    ...serviceSettings,
  })

  return {
    ...endpoints,
  }
}
