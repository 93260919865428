import {
  TServiceEndpointsSettings,
  useServiceEndpoints,
  useServiceCatalog,
} from '@/core/composables'
import { TCities, TCity } from '../models'
import { TCatalog, TFilters } from '@/core/types'

export const serviceSettings: TServiceEndpointsSettings = {
  version: '1',
  versionPrefix: 'v',
  config: {
    default: {
      model: 'cities',
    },
  },
}

export const useModuleService = () => {
  const { loadCatalog } = useServiceCatalog()

  const endpoints = useServiceEndpoints<TCities, TCity>({
    ...serviceSettings,
  })

  const loadStatesCatalog = async (options: TFilters) =>
    await loadCatalog<TCatalog[]>(options, {
      model: 'catalog/states',
      version: '1',
      versionPrefix: 'v',
    })

  return {
    ...endpoints,
    loadStatesCatalog,
  }
}
