import { RouteRecordRaw } from 'vue-router'
import { moduleRoutes as usersRoutes } from '@/modules/users'
import { moduleRoutes as candidatesRoutes } from '@/modules/candidates'
import { moduleRoutes as dashboardRoutes } from '@/modules/dashboard'
import { moduleRoutes as statesRoutes } from '@/modules/states'
import { moduleRoutes as citiesRoutes } from '@/modules/cities'
import { moduleRoutes as skillsRoutes } from '@/modules/skills'
import { errorRoutes } from './ErrorRoutes'

const DashboardLayout = () =>
  import(
    /* webpackChunkName: "dashboard-layout" */ '@/core/layouts/DashboardLayout.vue'
  )

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'candidates' },
    component: DashboardLayout,
    meta: {
      auth: true,
    },
    children: [
      ...dashboardRoutes,
      ...candidatesRoutes,
      ...skillsRoutes,
      ...statesRoutes,
      ...citiesRoutes,
      ...usersRoutes,
      ...errorRoutes,
    ],
  },
]
