import { type TDeleteOptions, useEndpoints } from './useEndpoints'

export const useDeleteRecord = () => {
  const { deleteFn } = useEndpoints()

  const deleteRecordFn = async <TDeleteRecord = void>(
    options: Omit<TDeleteOptions, 'ids'>,
  ): Promise<TDeleteRecord> => {
    return await deleteFn<TDeleteRecord>(options)
  }

  return { deleteRecordFn }
}
