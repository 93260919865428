import { createInjectionState } from '@vueuse/core'
import { computed, reactive, ref, toValue } from 'vue'
import {
  useAddRecord,
  useDeleteRecord,
  useLoadRecords,
  useUpdateRecord,
} from '../..'
import { TCatalog } from '@/core/types'
import {
  ToQueryStringFilters,
  useServiceHandlers,
  useToQueryString,
} from '@/core/composables'

const [useProvideModuleState, injectModule] = createInjectionState(() => {
  const currentPage = ref(1)
  const form = ref<TCatalog>({} as TCatalog)

  const filters = reactive<ToQueryStringFilters>({
    search: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const query = useToQueryString(filters, {
    dateSettings: {
      enableFormat: true,
    },
  })

  const serviceHandlers = useServiceHandlers<TCatalog, 'query'>({
    model: 'states',
    currentPage: computed(() => toValue(currentPage)),
    filterParams: computed(() => toValue(query.stringParams) ?? ''),
    queryType: 'query',
    primaryKey: 'uuid',
  })

  const { onDelete } = useDeleteRecord({
    handlers: {
      onSuccess: (_, payload) => {
        payload && serviceHandlers.deleteRecord(payload)
      },
    },
  })

  const { onAdd, addRecordMutation } = useAddRecord({
    handlers: {
      onSuccess: (payload) => payload && serviceHandlers.addRecord(payload),
    },
  })

  const { loadRecordsQuery } = useLoadRecords({
    currentPage,
    filters: query.stringParams,
    key: 'states',
  })

  const { onEdit, updateRecordMutation } = useUpdateRecord({
    handlers: {
      onSuccess: (payload) => payload && serviceHandlers.updateRecord(payload),
    },
  })

  const data = computed(() => loadRecordsQuery.data?.data)
  const pagination = computed(() => loadRecordsQuery.data?.meta)

  const loaders = computed(() => ({
    isLoadingRecords: loadRecordsQuery.isLoading,
    isAddingRecord: addRecordMutation.isPending,
    isUpdatingRecord: updateRecordMutation.isPending,
  }))

  const errorsBag = computed(() => ({
    onAdd: addRecordMutation.error?.response?.data,
    onEdit: updateRecordMutation.error?.response?.data,
  }))

  return {
    errorsBag,
    currentPage,
    data,
    filters,
    form,
    loaders,
    pagination,
    onDelete,
    onEdit,
    onAdd,
  }
})

export { useProvideModuleState }

export const useModuleState = () => {
  const module = injectModule()

  if (module == null)
    throw new Error(
      'Please call `useProvideModuleState` on the appropriate parent component',
    )

  return module
}
