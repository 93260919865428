import { TDefaultError, TDefaultOptions } from '@/core/types'
import { toReactive } from '@vueuse/core'
import { TToken } from '../models'
import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { useModuleService } from '../service'
import { useQueryEvents } from '@/core/composables'

export const useRefreshToken = ({ handlers }: TDefaultOptions<TToken, any>) => {
  const { refreshToken } = useModuleService()

  const onRefreshTokenQuery = useQuery<TToken, AxiosError<TDefaultError>>({
    queryKey: ['auth-user-refresh-token'],
    queryFn: refreshToken,
    enabled: false,
    retry: false,
  })

  useQueryEvents(onRefreshTokenQuery, {
    onError: handlers?.onError,
    onSuccess: handlers?.onSuccess,
  })

  const onRefreshToken = async () => {
    await onRefreshTokenQuery.refetch()
  }

  return toReactive({
    onRefreshTokenQuery,
    onRefreshToken,
  })
}
