/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { App } from 'vue'
import PrimeVue from 'primevue/config'
import { InputTextWrapper } from '@/core/components'
import { locales } from './locales'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import '@/core/assets/css/tailwind.css'

const config = {
  ripple: true,
  theme: 'none',
}

export const loadPrimeVue = (app: App) => {
  app.use(PrimeVue, {
    ...config,
    locale: locales.es,
  })

  app.use(ToastService)
  app.use(ConfirmationService)
  app.component('InputTextWrapper', InputTextWrapper)
}

export default PrimeVue
