import { TDefaultError, TDefaultOptions } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'
import { useNotification } from '@/core/composables'
import { TCity } from '../models'

export const useDeleteRecord = ({ handlers }: TDefaultOptions<void, TCity>) => {
  const { deleteRecord: deleteRecordFn } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const deleteRecordMutation = useMutation<
    void,
    AxiosError<TDefaultError>,
    TCity
  >({
    mutationFn: (payload) =>
      deleteRecordFn({
        ids: {
          parentId: payload.uuid,
        },
      }),
    onSuccess: handlers?.onSuccess,
    onError: (error) => errorHttpNotification({ error }),
  })

  const onDelete = async (payload: TCity) => {
    await deleteRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    onDelete,
    deleteRecordMutation,
  })
}
