import { useQueryClient } from '@tanstack/vue-query'
import { MaybeRef } from 'vue'
import { useNotification } from '@/core/composables'
import { DataWithPagination } from '@/core/types'

export type TInfiniteQueryData<T> = {
  pages: DataWithPagination<T>[]
}

type QueryType = 'query' | 'infiniteQuery'

type QueryDataType<T, Q extends QueryType> = Q extends 'query'
  ? DataWithPagination<T> | undefined
  : TInfiniteQueryData<T> | undefined

export const useServiceHandlers = <
  T extends { id?: number | string; uuid?: string },
  Q extends QueryType,
>(options: {
  model: string
  currentPage?: MaybeRef<number>
  filterParams?: MaybeRef<Record<string, string>> | MaybeRef<string>
  queryType: Q
  primaryKey?: 'uuid' | 'id'
}) => {
  const {
    model,
    currentPage = 1,
    filterParams = '',
    queryType = 'query',
    primaryKey = 'id',
  } = options

  const queryClient = useQueryClient()
  const { successWithActionNotification } = useNotification()

  const getQueryKey = () => {
    return queryType === 'query'
      ? [model, currentPage, filterParams]
      : [model, filterParams]
  }

  const addRecord = (payload: T) => {
    const queryKey = getQueryKey()
    const currentData = queryClient.getQueryData<QueryDataType<T, Q>>(queryKey)

    if (queryType === 'query') {
      const data = currentData as DataWithPagination<T>
      const updatedData: DataWithPagination<T> = {
        ...data,
        data: [payload, ...(data?.data ?? [])],
      }
      queryClient.setQueryData<DataWithPagination<T>>(queryKey, updatedData)
    } else {
      const pages = (currentData as TInfiniteQueryData<T>)?.pages ?? []
      if (pages.length > 0) {
        const firstPageData = pages[0].data
        const updatedFirstPageData = [payload, ...firstPageData]
        const updatedPages = [
          { ...pages[0], data: updatedFirstPageData },
          ...pages.slice(1),
        ]

        queryClient.setQueryData<TInfiniteQueryData<T>>(queryKey, {
          ...currentData,
          pages: updatedPages,
        })
      }
    }

    successWithActionNotification('ADDED', model)
  }

  const deleteRecord = (payload: T) => {
    const queryKey = getQueryKey()
    const currentData = queryClient.getQueryData<QueryDataType<T, Q>>(queryKey)

    if (queryType === 'query') {
      const data = currentData as DataWithPagination<T>

      const updatedData: DataWithPagination<T> = {
        ...data,
        data:
          data?.data.filter(
            (item: T) => item[primaryKey] !== payload[primaryKey],
          ) ?? [],
      }
      queryClient.setQueryData<DataWithPagination<T>>(queryKey, updatedData)
    } else {
      const pages = (currentData as TInfiniteQueryData<T>)?.pages ?? []
      const updatedPages = pages.map((page) => ({
        ...page,
        data: page.data.filter(
          (item: T) => item[primaryKey] !== payload[primaryKey],
        ),
      }))

      queryClient.setQueryData<TInfiniteQueryData<T>>(queryKey, {
        ...currentData,
        pages: updatedPages,
      })
    }

    successWithActionNotification('DELETED', model)
  }

  const deleteRecords = (payload: Array<number | string>) => {
    const queryKey = getQueryKey()
    const currentData = queryClient.getQueryData<QueryDataType<T, Q>>(queryKey)

    if (queryType === 'query') {
      const data = currentData as DataWithPagination<T>

      const updatedData: DataWithPagination<T> = {
        ...data,
        data:
          data?.data.filter(
            (item: T) => !payload.includes(item[primaryKey] ?? ''),
          ) ?? [],
      }
      queryClient.setQueryData<DataWithPagination<T>>(queryKey, updatedData)
    } else {
      const pages = (currentData as TInfiniteQueryData<T>)?.pages ?? []
      const updatedPages = pages.map((page) => ({
        ...page,
        data: page.data.filter(
          (item: T) => !payload.includes(item[primaryKey] ?? ''),
        ),
      }))

      queryClient.setQueryData<TInfiniteQueryData<T>>(queryKey, {
        ...currentData,
        pages: updatedPages,
      })
    }

    successWithActionNotification('DELETED', model)
  }

  const updateRecord = (payload: T) => {
    const queryKey = getQueryKey()
    const currentData = queryClient.getQueryData<QueryDataType<T, Q>>(queryKey)

    if (queryType === 'query') {
      const data = currentData as DataWithPagination<T>

      const updatedData: DataWithPagination<T> = {
        ...data,
        data:
          data?.data.map((item: T) =>
            item[primaryKey] === payload[primaryKey]
              ? { ...item, ...payload }
              : item,
          ) ?? [],
      }
      queryClient.setQueryData<DataWithPagination<T>>(queryKey, updatedData)
    } else {
      const pages = (currentData as TInfiniteQueryData<T>)?.pages ?? []
      const updatedPages = pages.map((page) => ({
        ...page,
        data: page.data.map((item: T) =>
          item[primaryKey] === payload[primaryKey]
            ? { ...item, ...payload }
            : item,
        ),
      }))

      queryClient.setQueryData<TInfiniteQueryData<T>>(queryKey, {
        ...currentData,
        pages: updatedPages,
      })
    }

    successWithActionNotification('UPDATED', model)
  }

  const invalidateQueries = async (key?: any) => {
    await queryClient.invalidateQueries({ queryKey: [key ?? model] })
  }

  return {
    addRecord,
    deleteRecord,
    deleteRecords,
    updateRecord,
    invalidateQueries,
  }
}
