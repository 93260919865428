import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from 'primevue/usetoast'
import { useGetHttpMessage } from './useGetHttpMessage'
import { useI18n } from 'vue-i18n'
import { TDefaultError } from '../types'

type TNotificationOptions = {
  summary?: string
  detail?: string
  closable?: boolean
  life?: number
}

type THttpErrorNotificationOptions = {
  error: AxiosError<TDefaultError>
  config?: TNotificationOptions
}

export const useNotification = () => {
  const toast = useToast()
  const { t } = useI18n()

  const errorNotification = (options: TNotificationOptions) => {
    toast.add({
      severity: 'error',
      ...options,
      closable: options.closable ?? true,
      life: options.life ?? 3500,
    })
  }

  const successNotification = (options: TNotificationOptions) => {
    toast.add({
      severity: 'success',
      ...options,
      closable: options.closable ?? true,
      life: options.life ?? 3500,
    })
  }

  const errorHttpNotification = (options: THttpErrorNotificationOptions) => {
    const { message } = useGetHttpMessage(
      options.error.response as AxiosResponse,
    )

    errorNotification({
      detail: message.value.errors?.join(',') ?? '',
      summary: message.value.message,
    })
  }

  const successWithActionNotification = (
    action: 'DELETED' | 'UPDATED' | 'ADDED' | 'DOWNLOADED',
    model: string,
  ) => {
    successNotification({
      summary: t(`${model}.MODEL`, 2),
      detail: t(`strings.CRUD_HELPER`, {
        attribute: t(`actions.${action}`),
      }),
    })
  }

  return {
    errorNotification,
    successNotification,
    errorHttpNotification,
    successWithActionNotification,
  }
}
