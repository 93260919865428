import { toReactive } from '@vueuse/core'
import { TLoginForm, TToken } from '../models'
import { AxiosError } from 'axios'
import { TDefaultError, TDefaultOptions } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { useModuleService } from '../service'

export const useLogin = ({ handlers }: TDefaultOptions<TToken, TLoginForm>) => {
  const { login } = useModuleService()

  const onLoginMutation = useMutation<
    TToken,
    AxiosError<TDefaultError>,
    TLoginForm
  >({
    mutationFn: login,
    onSuccess: handlers?.onSuccess,
  })

  const onLogin = async (payload: TLoginForm) => {
    await onLoginMutation.mutateAsync(payload)
  }

  return toReactive({
    onLogin,
    onLoginMutation,
  })
}
