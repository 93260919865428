import { authRoles } from '@/core/constants'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN } = authRoles

const Users = () =>
  import(/* webpackChunkName: "login-view" */ './presentation/pages/index.vue')

export const moduleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Usuarios',
      auth: {
        roles: [ADMIN], // Roles permitidos
        redirect: '/auth/login', // Redirección si no está autenticado
        forbiddenRedirect: '/error', // Redirección si no tiene los roles necesarios
      },
    },
  },
]
