import axios from 'axios'

// Create Instances
const api = axios.create({
  headers: {
    ...(import.meta.env.VITE_ENVIROMENT === 'alpha'
      ? { 'ngrok-skip-browser-warning': 69420 }
      : {}),
    Accept: 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    const tokenStorage = localStorage.getItem('auth_access_token')
    const token = tokenStorage ? JSON.parse(tokenStorage) : null

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// api.interceptors.response.use(
//   (response: AxiosResponse) => response,
//   (error: AxiosError<TDefaultError>) => {
//     console.log(error)

//     if (error.status === 401) {
//       deleteCookie('auth_access_token')
//       window.localStorage.removeItem('auth_access_token')
//       removeAuthHeader()
//       auth.onLogoutFn()
//       // window.location.reload()
//     }

//     return Promise.reject(error)
//   },
// )

// Headers
export const setAuthHeader = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const removeAuthHeader = () => {
  delete api.defaults.headers.common['Authorization']
}

export const setBaseUrl = (baseUrl: string) => {
  api.defaults.baseURL = baseUrl
}

export default api
