import { useEndpoints } from '@/core/composables'
import { TLoginForm, TToken, TRecoverForm, TResetPasswordForm } from '../models'
import { TUser } from '@/core/types'

export const useModuleService = () => {
  const { postFn, getFn } = useEndpoints()

  return {
    login: async (payload: TLoginForm): Promise<TToken> =>
      await postFn<TLoginForm, TToken>({
        endpoint: '/v1/auth/login',
        payload,
      }),
    logout: async (): Promise<any> =>
      await postFn<any, any>({
        endpoint: '/v1/auth/logout',
        payload: {},
      }),
    refreshToken: async (): Promise<TToken> =>
      await getFn<TToken>({
        endpoint: '/v1/auth/refresh',
      }),
    getUser: async (): Promise<TUser> =>
      await getFn<TUser>({
        endpoint: '/v1/auth/user',
      }),
    requestPasswordChange: async (payload: TRecoverForm) =>
      await postFn<TRecoverForm>({
        endpoint: '/v1/auth/password-change/request',
        payload,
      }),
    resetPassword: async (payload: TResetPasswordForm) =>
      await postFn<TResetPasswordForm>({
        endpoint: '/v1/auth/password-change/reset',
        payload,
      }),
  }
}
