import { TFilters } from '@/core/types'
import { useEndpoints } from './useEndpoints'

export const useLoadRecords = () => {
  const { getAllFn } = useEndpoints()

  const loadRecordsFn = async <TLoadData = any>(
    options: TFilters,
  ): Promise<TLoadData> => {
    return await getAllFn<TLoadData>(options)
  }

  return { loadRecordsFn }
}
