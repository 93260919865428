import type { App } from 'vue'
import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'

export type SentryOptions = {
  app: App
  router: Router
}

export const useSentry = (options: SentryOptions) => {
  Sentry.init({
    app: options.app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router: options.router,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', /^\/api/],
    profilesSampleRate: 0.1,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  })
}
