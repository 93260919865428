import { AxiosResponse } from 'axios'

type TDownloadOptions = {
  response?: AxiosResponse
  extension?: string
  formatName?: string
}
export const useDownload = () => {
  const downloadFn = (options: TDownloadOptions) => {
    const { extension, formatName, response } = options

    const filename = `${formatName}.${extension}`

    const blob = new Blob([response?.data], {
      type: 'application/octet-stream',
    })

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return { downloadFn }
}
