// src/core/store/auth.ts
// src/core/store/auth.ts

import { defineStore } from 'pinia'
import { removeAuthHeader, setAuthHeader } from '@/core/config/axios'
import { ref, computed } from 'vue'
import {
  TToken,
  useGetAuthUser,
  useLogin,
  useLogout,
  useRefreshToken,
  useRequestRecover,
  useResetPassword,
} from '@/modules/auth/data'
import Cookies from 'cookies-ts'
import { useLocalStorage, useToggle } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { TUser } from '@/core/types'

type TSetTokenConfig = {
  fetchUser?: boolean
  refreshToken?: boolean
  redirectRouteName?: string
}

type TSetTokenOptions = {
  tokenSettings: TToken
  config?: TSetTokenConfig
}

export const useAuthStore = defineStore('auth', () => {
  const cookies = new Cookies()
  const router = useRouter()

  const token = useLocalStorage<string | null | undefined>(
    'access_token',
    undefined,
  )

  const user = ref<TUser | undefined>(undefined)
  const [isAuthenticating, toggleIsAuthenticating] = useToggle(true)

  const { onGetUser, onGetUserQuery } = useGetAuthUser()
  const { onRequestRecover, onRequestRecoverMutation } = useRequestRecover({
    handlers: {
      onSuccess: async () => {
        router.replace({ name: 'passwordRecoveryConfirmation' })
      },
    },
  })
  const { onResetPassword, onResetPasswordMutation } = useResetPassword({
    handlers: {
      onSuccess: async () => {
        router.replace({ name: 'login' })
      },
    },
  })

  const { onLogin, onLoginMutation } = useLogin({
    handlers: {
      onSuccess: async (response) => {
        response &&
          (await setToken({
            tokenSettings: response,
            config: {
              fetchUser: true,
              redirectRouteName: 'candidates',
            },
          }))
      },
    },
  })

  const { onLogout } = useLogout({
    handlers: {
      onSuccess: () => {
        toggleIsAuthenticating(true)
        onLogoutFn()
      },
    },
  })

  const { onRefreshToken, onRefreshTokenQuery } = useRefreshToken({
    handlers: {
      onError: async () => {
        onLogoutFn()
        toggleIsAuthenticating(false)
      },
      onSuccess: async (response) => {
        response &&
          (await setToken({
            tokenSettings: response,
            config: {
              fetchUser: true,
            },
          }))
      },
    },
  })

  const onLogoutFn = () => {
    token.value = undefined
    user.value = undefined
    localStorage.removeItem('access_token')
    cookies.remove('access_token')
    removeAuthHeader()
    router.replace({ name: 'login', params: { logout: 'ok' } })
    toggleIsAuthenticating(false)
  }

  const onFetchUser = async () => {
    await onGetUser()
      .then((response) => {
        if (response.data && response.data?.uuid) {
          setUser(response.data)
        } else {
          onLogoutFn()
        }
      })
      .catch(() => {
        onLogoutFn()
      })
      .finally(() => {
        toggleIsAuthenticating(false)
      })
  }

  const checkAuth = async () => {
    const savedToken = token.value || cookies.get('access_token')

    if (savedToken) {
      await setToken({
        tokenSettings: {
          access_token: savedToken,
          expires_in: 86400,
          token_type: 'Bearer',
        },
      })
      await onRefreshToken()
      await onFetchUser()
    } else {
      toggleIsAuthenticating(false)
    }
  }

  const setToken = async (options: TSetTokenOptions) => {
    const { tokenSettings, config } = options

    token.value = tokenSettings.access_token
    cookies.set('access_token', tokenSettings.access_token, {
      expires: tokenSettings.expires_in / (60 * 60 * 24),
      secure: true,
    })
    setAuthHeader(tokenSettings.access_token)

    if (config?.refreshToken) {
      await onRefreshToken()
    }

    if (config?.fetchUser) {
      await onFetchUser()
    }

    if (config?.redirectRouteName) {
      await router.push({ name: config.redirectRouteName })
    }
  }

  const setUser = (payload: TUser) => {
    user.value = payload
  }

  // Computed properties
  const isAuthenticated = computed(() => !!user.value)
  const userRoles = computed(() => user.value?.roles || [])

  // Loaders
  const loaders = computed(() => ({
    isLoadingUser: onGetUserQuery.isLoading,
    isLogging: onLoginMutation.isPending,
    isRefreshingToken: onRefreshTokenQuery.isLoading,
    isAuthenticating: isAuthenticating.value,
    isResetPassword: onResetPasswordMutation.isPending,
    isRecoveryPassword: onRequestRecoverMutation.isPending,
  }))

  //Errors
  const errorsBag = computed(() => ({
    login: onLoginMutation.error?.response?.data,
    reset: onResetPasswordMutation.error?.response?.data,
    recovery: onRequestRecoverMutation.error?.response?.data,
  }))

  return {
    errorsBag,
    loaders,
    token,
    user,
    isAuthenticated,
    userRoles,
    onResetPassword,
    onLogin,
    onLogout,
    setUser,
    checkAuth,
    onLogoutFn,
    onRequestRecover,
  }
})
