import { TFilters } from '@/core/types'
import { useAddRecord } from './useAddRecord'
import { useLoadRecords } from './useLoadRecords'
import { TServiceEndpointsSettings } from './useServiceEndpoints'

export type TServiceCatalogOptions = {
  model: string
} & Omit<TServiceEndpointsSettings, 'config'>

export type TRequestCatalogsPayload = {
  ids: number[]
}

export const useServiceCatalog = () => {
  const { loadRecordsFn } = useLoadRecords()
  const { addRecordFn } = useAddRecord()

  return {
    loadCatalog: async <TData>(
      options: TFilters,
      settings: TServiceCatalogOptions,
    ) => {
      const { version = '', versionPrefix = '', model = '' } = settings

      const endpoint = `${versionPrefix}${version}/${model}`

      return await loadRecordsFn<TData>({
        ...options,
        endpoint,
      })
    },
    requestCatalogs: async <TData>(
      payload: TRequestCatalogsPayload,
      settings: { endpoint: string },
    ) =>
      await addRecordFn<TRequestCatalogsPayload, TData>({
        endpoint: settings.endpoint,
        payload,
      }),
  }
}
