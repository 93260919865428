import { createApp } from 'vue'
import App from './App.vue'
import { settings } from './core/settings/DynamicSettings'
import { createPinia } from 'pinia'
import { setBaseUrl, i18n } from './core/config'
import router from './core/config/router'
const app = createApp(App)
const pinia = createPinia()
import 'primeicons/primeicons.css'
import { loadPrimeVue } from './core/config/theme'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { useAuthStore } from './modules/auth'
import { useSentry } from './core/composables'

setupApp().then(() => {
  loadPrimeVue(app)

  app.use(pinia).use(router).use(i18n).use(VueQueryPlugin)

  const authStore = useAuthStore()

  authStore.checkAuth().then(() => {
    app.mount('#app')
  })
})

async function setupApp() {
  useSentry({
    app,
    router,
  })

  if (settings.ENABLE_MSW) {
    const { worker } = await import('./mocks/browser')
    worker.start({ onUnhandledRequest: 'bypass' })
    setBaseUrl(`${window.origin}/api`)
  } else {
    setBaseUrl(import.meta.env.VITE_API_URL)
  }
}
