import { useEndpoints, type TPutOptions } from './useEndpoints'

export const useUpdateRecord = () => {
  const { putFn } = useEndpoints()

  const updateRecordFn = async <TUpdateRecord>(
    options: TPutOptions<TUpdateRecord>,
  ): Promise<TUpdateRecord> => {
    return await putFn<TUpdateRecord>(options)
  }

  return { updateRecordFn }
}
