import { useNotification, useQueryEvents } from '@/core/composables'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { TCatalog, TDefaultError, TLoadOptions } from '@/core/types'
import { useQuery } from '@tanstack/vue-query'
import { useModuleService } from '../services'

export const useLoadStates = ({
  enabled = true,
  handlers,
}: TLoadOptions<TCatalog[]>) => {
  const { loadStatesCatalog } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const loadStatesQuery = useQuery<TCatalog[], AxiosError<TDefaultError>>({
    queryKey: ['states-catalog'],
    queryFn: () => loadStatesCatalog({}),
    refetchOnWindowFocus: false,
    enabled: !!enabled,
  })

  useQueryEvents(loadStatesQuery, {
    onError: (error) => errorHttpNotification({ error }),
    onSuccess: (data) => handlers?.onSuccess?.(data),
  })

  return toReactive({
    loadStatesQuery,
  })
}
