import { AxiosResponse } from 'axios'
import { ref } from 'vue'
import { TDefaultError } from '../types'

export const useGetHttpMessage = (payload: AxiosResponse<TDefaultError>) => {
  const message = ref<TDefaultError>({
    message: payload.data?.message ?? 'Ups!',
    errors: payload.data?.errors ?? [],
  })

  return {
    message,
  }
}
