import { TDefaultError, TDefaultOptions, TUser, TUserForm } from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { toReactive } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useModuleService } from '../services'

export const useAddRecord = ({
  handlers,
}: TDefaultOptions<TUser, TUser & TUserForm>) => {
  const { addRecord: addRecordFn } = useModuleService()

  const addRecordMutation = useMutation<
    TUser,
    AxiosError<TDefaultError>,
    TUser & TUserForm
  >({
    mutationFn: (payload: TUser) =>
      addRecordFn({
        payload,
      }),
    onSuccess: handlers?.onSuccess,
  })

  const onAdd = async (payload: TUser & TUserForm) => {
    await addRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    onAdd,
    addRecordMutation,
  })
}
