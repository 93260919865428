<script setup lang="ts">
import bgError from '@/core/assets/images/error.png'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const { t } = useI18n()
const goToDashboard = () => {
  router.replace({ name: 'dashboard' })
}
</script>
<template>
  <div class="flex items-center justify-center w-full">
    <div
      class="w-full max-w-[1300px] flex flex-col justify-center items-center lg:flex-row h-full"
    >
      <div
        class="flex flex-col w-full lg:w-1/2 gap-4 justify-center items-center lg:items-start"
      >
        <h1 class="text-7xl font-semibold">
          {{ t('strings.ITS_EMPTY_HERE') }}
        </h1>
        <p class="lg:max-w-96">{{ t('strings.NO_FOUND') }}</p>

        <Button
          class="w-1/2 mt-10 uppercase"
          variant="outlined"
          :label="t('strings.BACK_TO_HOMEPAGE')"
          @click="goToDashboard"
        />
      </div>

      <div class="w-full lg:w-1/2">
        <img class="max-w-full" :src="bgError" alt="error" />
      </div>
    </div>
  </div>
</template>
