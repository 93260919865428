import { useEndpoints, type TDeleteOptions } from './useEndpoints'

export const useDeleteRecords = () => {
  const { deleteManyFn } = useEndpoints()

  const deleteRecordsFn = async <TDeleteRecord = void>(
    options: TDeleteOptions,
  ): Promise<TDeleteRecord> => {
    return await deleteManyFn<TDeleteRecord>(options)
  }

  return { deleteRecordsFn }
}
