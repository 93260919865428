import { useNotification } from '@/core/composables'
import { toReactive } from '@vueuse/core'
import { TCandidate } from '../models'
import { AxiosError } from 'axios'
import {
  TDefaultError,
  TDefaultOptions,
  TNestedRouteParams,
} from '@/core/types'
import { useMutation } from '@tanstack/vue-query'
import { useModuleService } from '../services'

export const useLoadRecord = ({
  handlers,
}: TDefaultOptions<TCandidate, TNestedRouteParams>) => {
  const { loadRecord: loadRecordFn } = useModuleService()

  const { errorHttpNotification } = useNotification()

  const loadRecordMutation = useMutation<
    TCandidate,
    AxiosError<TDefaultError>,
    TNestedRouteParams
  >({
    mutationFn: (payload) => loadRecordFn({ ...payload }),
    onError: (error) => errorHttpNotification({ error }),
    onSuccess: (data) => handlers?.onSuccess?.(data),
  })

  const onGetCandidate = async (payload: TNestedRouteParams) => {
    await loadRecordMutation.mutateAsync(payload)
  }

  return toReactive({
    loadRecordMutation,
    onGetCandidate,
  })
}
