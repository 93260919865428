<script lang="ts" setup>
import { useAuthStore } from '@/modules/auth'

const auth = useAuthStore()
</script>

<template>
  <div>
    <Transition name="fade">
      <section
        v-if="auth.loaders.isAuthenticating"
        class="fixed z-50 top-0 left-0 w-screen h-screen bg-surface-0 dark:bg-surface-700 flex items-center justify-center"
      >
        <ProgressSpinner />
      </section>
    </Transition>
  </div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 10s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
