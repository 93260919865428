import { createI18n } from 'vue-i18n'
import es from './lang/es.json'
import en from './lang/en.json'

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  availableLocales: ['es', 'en'],
  locale: 'en',
  fallbackLocale: 'es',
  messages: { es, en },
})
