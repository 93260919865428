import { useEndpoints } from './useEndpoints'

export type TRecordData = {
  id?: string | number
  action?: string
  data?: any
}

export type TActionRecordOptions = {
  endpoint?: string
} & TRecordData

export const useActionRecord = () => {
  const { putFn } = useEndpoints()

  const actionRecordFn = async <TActionRecord = any>(
    options: TActionRecordOptions,
  ): Promise<TActionRecord> => {
    return await putFn<TActionRecord>({
      ...options,
      payload: options?.data,
    })
  }

  return { actionRecordFn }
}
