import ErrorPage from '@/core/components/errors/BaseErrorPage.vue'
import { RouteRecordRaw } from 'vue-router'

export const errorRoutes: Array<RouteRecordRaw> = [
  {
    path: '/error',
    name: 'errorPage',
    component: ErrorPage,
  },
]
