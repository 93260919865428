import type { TFilters, TNestedRouteParams } from '../../types'
import { useLoadRecords } from './useLoadRecords'
import { type TLoadRecordOptions, useLoadRecord } from './useLoadRecord'
import { useAddRecord } from './useAddRecord'
import { useUpdateRecord } from './useUpdateRecord'
import { useDeleteRecord } from './useDeleteRecord'
import type { TPostOptions, TDeleteOptions, TPutOptions } from './useEndpoints'
import { useDeleteRecords } from './useDeleteRecords'
import {
  type TActionRecordOptions,
  TRecordData,
  useActionRecord,
} from './useActionRecord'
import { computed, ref, toValue } from 'vue'

export type TServiceEndpointDefaultSettings = {
  model?: string
}

export type TServiceEndpointNestedSettings = {
  model?: string
  enabled?: boolean
}

export type TServiceEndpointConfiguration = {
  default?: TServiceEndpointDefaultSettings
  nested?: TServiceEndpointNestedSettings
}

export type TServiceEndpointsSettings = {
  version?: string
  versionPrefix?: string
  config?: TServiceEndpointConfiguration
}

type TConfigOptions = TNestedRouteParams['ids'] &
  Pick<TRecordData, 'action'> & {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'DELETE-MANY'
  }

export const useServiceEndpoints = <TData = any, TModel = any>(
  settings: TServiceEndpointsSettings,
) => {
  const { version = '', versionPrefix = '', config } = settings

  const baseUrl = ref(`${versionPrefix}${version}/${config?.default?.model}`)

  const isNestedRoutesEnabled = computed(
    () => !!config?.nested?.enabled && !!config.nested.model,
  )

  const { loadRecordsFn } = useLoadRecords()
  const { loadRecordFn } = useLoadRecord()
  const { addRecordFn } = useAddRecord()
  const { updateRecordFn } = useUpdateRecord()
  const { deleteRecordFn } = useDeleteRecord()
  const { deleteRecordsFn } = useDeleteRecords()
  const { actionRecordFn } = useActionRecord()

  const getNestedEndpoint = (options: TConfigOptions) => {
    const method = options?.method
    const childId = toValue(options?.childId)
    const childIdRoute = childId ? `/${childId}` : ''
    const parentId = toValue(options?.parentId)
    const parentIdRoute = parentId ? `/${parentId}` : ''
    const action = options.action ? `/${options.action}` : ''

    if (isNestedRoutesEnabled.value) {
      return method === 'DELETE-MANY'
        ? config?.nested?.model
        : `${baseUrl.value}${parentIdRoute}/${config?.nested?.model}${childIdRoute}${action}`
    }

    if (parentId) {
      return `${baseUrl.value}${parentIdRoute}${action}`
    }

    return baseUrl.value
  }

  const loadRecords = async (
    options: TFilters & TNestedRouteParams,
  ): Promise<TData> => {
    const { endpoint } = checkServiceSettings({
      childId: options.ids?.childId,
      parentId: options.ids?.parentId,
    })

    return await loadRecordsFn<TData>({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  const loadRecord = async (
    options: TLoadRecordOptions & TNestedRouteParams,
  ): Promise<TModel> => {
    const { endpoint } = checkServiceSettings({
      childId: options.ids?.childId,
      parentId: options.ids?.parentId,
    })

    return await loadRecordFn<TModel>({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  const addRecord = async (
    options: TPostOptions<TModel> & TNestedRouteParams,
  ): Promise<TModel> => {
    const { endpoint } = checkServiceSettings({
      childId: options.ids?.childId,
      parentId: options.ids?.parentId,
    })
    return await addRecordFn<TModel, TModel>({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  const updateRecord = async (
    options: TPutOptions<TModel> & TNestedRouteParams,
  ): Promise<TModel> => {
    const { endpoint } = checkServiceSettings({
      childId: options.ids?.childId,
      parentId: options.ids?.parentId,
    })
    return await updateRecordFn<TModel>({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  const deleteRecord = async (
    options: Omit<TDeleteOptions, 'ids'> & TNestedRouteParams,
  ): Promise<void> => {
    const { endpoint } = checkServiceSettings({
      childId: options.ids?.childId,
      parentId: options.ids?.parentId,
    })
    return await deleteRecordFn({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  const actionRecord = async (
    options: TActionRecordOptions & TNestedRouteParams,
  ): Promise<TModel> => {
    const { endpoint } = checkServiceSettings({
      childId: options.ids?.childId,
      parentId: options.ids?.parentId,
      action: options.action,
    })

    return await actionRecordFn<TModel>({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  const checkServiceSettings = (options?: TConfigOptions) => {
    const endpoint = getNestedEndpoint({
      childId: options?.childId,
      parentId: options?.parentId,
      action: options?.action,
      method: options?.method,
    })

    return { endpoint }
  }
  /*
    deleteRecords
    TODO: Nested route implementation, is necessary to be defined with the backend team
  */
  const deleteRecords = async (options: TDeleteOptions): Promise<void> => {
    const { endpoint } = checkServiceSettings({
      method: 'DELETE-MANY',
    })

    return await deleteRecordsFn({
      ...options,
      endpoint: toValue(endpoint),
    })
  }

  return {
    loadRecords,
    loadRecord,
    addRecord,
    updateRecord,
    deleteRecord,
    deleteRecords,
    actionRecord,
  }
}
