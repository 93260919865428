import {
  TExportRecordsFnOptions,
  TServiceEndpointsSettings,
  useExportRecords,
  useServiceEndpoints,
} from '@/core/composables'
import { TCandidates, TCandidate } from '../models'

export const serviceSettings: TServiceEndpointsSettings = {
  version: '1',
  versionPrefix: 'v',
  config: {
    default: {
      model: 'candidates',
    },
  },
}

export const useModuleService = () => {
  const { config } = serviceSettings

  const endpoints = useServiceEndpoints<TCandidates, TCandidate>({
    ...serviceSettings,
  })

  const { exportRecordsFn } = useExportRecords()

  return {
    ...endpoints,
    exportRecords: async (options: TExportRecordsFnOptions) =>
      await exportRecordsFn({
        ...options,
        model: config?.default?.model ?? '',
        extension: 'xlsx',
      }),
  }
}
