import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authRoutes, routes as dashboardRoutes, errorRoutes } from './modules'
import { useAuthStore } from '@/modules/auth'

type TMetaAuthOptions = {
  roles: string[] // Roles permitidos
  redirect: string // Redirección si no está autenticado
  forbiddenRedirect: string
}

const routes: Array<RouteRecordRaw> = [
  ...errorRoutes,
  ...authRoutes,
  ...dashboardRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  // Actualizar el título de la página
  document.title = `${import.meta.env.VITE_APP_NAME} ${to.meta?.title ? ' | ' + to.meta.title : ''}`

  // Obtener la configuración de autenticación de la ruta
  const requiresAuth = to.meta?.auth as TMetaAuthOptions

  if (!requiresAuth) {
    // Ruta pública, permitir acceso
    return next()
  }

  // Esperar a que se resuelva la verificación de autenticación si está autenticando
  if (authStore.loaders.isAuthenticating) {
    await authStore.checkAuth()
  }

  // Verificar si el usuario está autenticado
  if (!authStore.isAuthenticated) {
    const redirect = requiresAuth.redirect || '/auth/login'
    return next({ path: redirect })
  }

  // Si la ruta solo requiere autenticación sin roles específicos
  if (typeof requiresAuth === 'boolean' && requiresAuth) {
    return next()
  }

  // Si la ruta requiere roles específicos
  if (typeof requiresAuth === 'object') {
    const userRoles = authStore.userRoles
    const allowedRoles = requiresAuth.roles || []
    const hasAccess = allowedRoles.some((role: string) =>
      userRoles.includes(role),
    )

    if (hasAccess) {
      return next()
    } else {
      const forbiddenRedirect = requiresAuth.forbiddenRedirect || '/error'
      return next({ path: forbiddenRedirect })
    }
  }

  // Permitir el acceso por defecto
  return next()
})

export default router
