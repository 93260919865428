import { toReactive } from '@vueuse/core'
import { TDefaultError, TDefaultOptions } from '@/core/types'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/vue-query'
import { useModuleService } from '../service'
import { TResetPasswordForm } from '../models'

export const useResetPassword = ({
  handlers,
}: TDefaultOptions<void, TResetPasswordForm>) => {
  const { resetPassword } = useModuleService()
  const onResetPasswordMutation = useMutation<
    void,
    AxiosError<TDefaultError>,
    TResetPasswordForm
  >({
    mutationFn: resetPassword,
    onSuccess: handlers?.onSuccess,
  })

  const onResetPassword = async (email: TResetPasswordForm) => {
    await onResetPasswordMutation.mutateAsync(email)
  }

  return toReactive({
    onResetPassword,
    onResetPasswordMutation,
  })
}
